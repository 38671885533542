<template>
  <div>
    <div class="inline-flex align-items-center gap-1 sm:gap-2 bg-slate-900 p-1 sm:p-2 mb-2 rounded-xl overflow-x-scroll scrollbar-hide max-w-full">
      <h2
        class="py-2 px-3 sm:px-4 rounded-lg flex items-center font-semibold tracking-normal sm:tracking-wider text-xs sm:text-base leading-none category-title mb-0 text-slate-200 cursor-pointer hover:text-white bg-slate-600/25 text-center"
        :class="{ 'text-white !bg-slate-600': tabActive === 'activity' }"
        @click.prevent.stop="toggleTab('activity')"
      >
        Activity
      </h2>
      <div class="inline-flex align-items-center relative">
        <h2
          class="py-2 px-2 sm:px-4 rounded-lg flex items-center font-semibold tracking-normal sm:tracking-wider text-xs sm:text-base leading-none category-title mb-0 text-slate-200 cursor-pointer hover:text-white bg-slate-600/25 text-center whitespace-nowrap"
          :class="{ 'text-white !bg-slate-600 rounded-r-none': anyOfRaceTabsActive }"
          @click.prevent.stop="toggleTab('monthlyreelrace')"
        >
          {{ type === 'liverace' ? 'Live Race' : 'Races' }}
        </h2>
        <template v-if="anyOfRaceTabsActive">
          <h2
            v-for="(tab, index) in reelRacesTabs"
            :key="tab.name"
            class="py-2 px-2 sm:px-4 flex items-center font-semibold tracking-normal sm:tracking-wider text-xs sm:text-base leading-none category-title mb-0 text-slate-200 cursor-pointer hover:text-white bg-slate-600/25 text-center"
            :class="{
              'text-white !bg-slate-500': tabActive === tab.name,
              'rounded-r-lg': index === reelRacesTabs.length - 1
            }"
            @click.prevent.stop="toggleTab(tab.name)"
          >
            {{ tab.label }}
          </h2>
        </template>
        <span v-if="false" class="absolute -top-4 -right-1 lg:-top-4 lg:-right-2 rotate-6">
          <span class="animate-small-ping absolute bottom-0 inline-flex h-[13px] sm:h-[20px] w-full rounded-full bg-sky-400 opacity-40"/>
          <span class="bg-blue-500 text-white rounded-full text-3xs py-0.5 px-1.5 text-center shadow-xl shadow-slate-900/80 relative">NEW</span>
        </span>
      </div>
    </div>
    <div v-if="tabActive === 'activity'" class="h-[510px] lg:h-[698px] overflow-y-hidden overflow-x-auto">
      <ListActivityGame
        scroll-disabled
        :max-count="10"
      />
    </div>
    <div v-else :key="tabActive" class="min-h-[510px] lg:min-h-[698px] overflow-hidden">
      <Spinner v-if="leaderboardLoading"/>
      <LeaderboardCoreInstant
        :count="10"
        :leaderboard-schedule-name="leaderboardScheduleName"
        :use-last="useLast"
        hide-close-button
        show-title
        hide-name
        size="lg"
        placement="page"
        class="mt-1"
        @leaderboard-loaded="leaderboardLoaded"
      />
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useDeviceStore } from '@/store/device';

const { $dayjs } = useNuxtApp();

const slotsDailyTime = $dayjs('2024-09-10T05:00').valueOf();

const props = defineProps({
  initTab: {
    type: String,
    default: '',
    validator: value => [
      'reelrace',
      'activity',
      '',
    ].includes(value),
  },
  type: {
    type: String,
    default: 'reelrace',
    validator: value => [
      'reelrace',
      'liverace',
    ].includes(value),
  },
});

const emits = defineEmits([
  'leaderboardLoaded',
]);

const deviceStore = useDeviceStore();
const { isMetaMaskMobile, } = storeToRefs(deviceStore);

const leaderboardLoading = ref(false);
const dynamicTabActive = computed(() => isMetaMaskMobile.value ? 'reelrace' : 'activity');
const tabActive = ref(props.initTab || dynamicTabActive.value);
const anyOfRaceTabsActive = computed(() => ['reelrace', 'weeklyreelrace', 'monthlyreelrace', 'yesterdayreelrace', 'livereelrace', 'weeklylivereelrace',].includes(tabActive.value));
const reelRacesTabs = computed(() => {

  const tabs = [
    // { name: 'yesterdayreelrace', label: '-24h', },
    { name: 'monthlyreelrace', label: '$1m Race', },
    { name: 'reelrace', label: 'Daily', },
  ];
  return tabs;
});
const leaderboardScheduleName = computed(() => {
  if ((tabActive.value === 'reelrace' || tabActive.value === 'yesterdayreelrace') && props.type === 'liverace') {
    return 'LiveDaily';
  } else if (tabActive.value === 'monthlyreelrace') {
    return 'MonthlyWagering';
  } else if (tabActive.value === 'weeklyreelrace') {
    return 'SlotsWeekly';
  } else if (tabActive.value === 'weeklylivereelrace') {
    return 'LiveWeekly';
  }

  return 'SlotsDaily';
});
const useLast = computed(() => {
  if (tabActive.value === 'yesterdayreelrace') {
    return true;
  }
  return Date.now() <= Date.UTC(2023, 8, 2, 5, 0, 0, 0);
});

function leaderboardLoaded(eventData) {
  leaderboardLoading.value = !eventData;
  emits('leaderboardLoaded', eventData);
}

function toggleTab(tab) {
  tabActive.value = tab;
}
</script>
